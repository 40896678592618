const languages = {
	Afrikaans:"af",
	Albanian:"sq",
	Arabic:"ar",
	Aragonese:"an",
	Armenian:"hy",
	Azrbaijani: "az",
	Basque:"eu",
	Belarusian:"be",
	Bengali:"bn",
	Bosnian:"bs",
	Breton:"br",
	Bulgarian:"bg",
	Burmese:"my",
	Catalan:"ba",
	ChineseSimplified:"zh-CN",
	ChineseBilingual:"ze",
	Croatian:"hr",
	Czech:"cs",
	Danish:"da",
	Dutch:"nl",
	English:"en",
	Esperanto:"eo",
	Estonian: "et",
	Finnish:"fi",
	French:"fr",
	Gaelic:"gd",
	Georgian:"ka",
	German:"de",
	Greek:"el",
	Hebrew:"he",
	Hindi:"hi",
	Hungarian:"hu",
	Icelandic:"is",
	Igbo:"ig",
	Indonesian:"id",
	Irish:"ga",
	Italian:"it",
	Japanese:"ja",
	Kazakh:"kk",
	Khmer:"km",
	Korean:"ko",
	Kurdish:"ku",
	Latvian:"lv",
	Lithuanian:"lt",
	Macedonian: "mk",
	Malay:"ms",
	Manipuri: "ma",
	Mongolian:"mn",
	Montenegrin:"me",
	Navajo:"nv",
	Nepali: "ne",
	Norwegian:"no",
	Persian:"fa",
	Polish:"pl",
	Portuguese:"pt-PT",
	"Portuguese BR":"pt-PT",
	Romanian:"ro",
	Russian:"ru",
	Serbian:"sr",
	Sindhi:"sd",
	Slovak:"sk",
	Slovenian:"sl",
	Somali:"so",
	Spanish:"es",
	Swahili:"sw",
	Swedish:"sv",
	Tagalog: "tl",
	Tamil:"ta",
	Thai:"th",
	Turkish:"tr",
	Ukrainian:"uk",
	Urdu:"ud",
	Uzbek: "uz",
	Vietnamese:"vi"
}


export { languages };